<template lang="html">
  <div class="ChartMonitor" :class="{'ChartMonitor--ConfigOpen':showConfig}">
    <div class="ChartMonitor--Content">
      <div class="ChartMonitor--AddedFilters" v-if="getFilterFormated().length > 0">
        <h2>{{$t('watmonitor_filters_in_question')}}</h2>
        <FilterTag :type="'Chart'" :text="f.text" v-for="f in getFilterFormated()" v-on:remove="removeFilter(f)" v-on:edit="editFilter(f)" :allowDelete="!disableActions" :allowEdit="!disableActions"/>
      </div>
      <div class="ChartMonitor--HeaderButtons" v-if="question.taskNum == -1 && question.questionId == 1">
        <p :class="{'selected':geographicSelected == 0}" v-on:click="changeGeographicChart(0)">Provincia</p>
        <p :class="{'selected':geographicSelected == 1}" v-on:click="changeGeographicChart(1)" v-show="regions.length > 0">{{ $t('watmonitor_region') }}</p>
        <p :class="{'selected':geographicSelected == 2}" v-on:click="changeGeographicChart(2)" v-show="nielsen.length>0">{{ $t('watmonitor_nielsen') }}</p>
      </div>
      <div class="ChartMonitor--HeaderButtons" v-if="question.questionType == 'MULTINUMBER'">
        <p :class="{'selected':value == num}" v-on:click="changeMultinumberChart(num)" v-for="num in getQuestionTextNum(question.questionId, question.taskNum)">{{$t('watmonitor_answer')}} {{num}}</p>
      </div>
      <div class="ChartMonitor--Title">
        <QuestionIMG :id="'QuestionImage_'+_uid" v-if="question.questionType != 'RANKING'" :name="question.taskNum == -1 && question.questionId == 1 ? 'PROVINCE' : question.taskNum == -1 && question.questionId == 2 ? 'AGE' : question.taskNum == -1 && question.questionId == 3 ? 'GENDER' : question.questionType" />
        <QuestionIMG :id="'QuestionImage_'+_uid" v-if="question.questionType == 'RANKING'" :name="question.questionType+'-STARS'" />
        <h1 :id="'QuestionTitle_'+index" :style="{'font-family':fontFamily}">
          <span v-if="question.taskNum != -1 && !hideQuestionIndex">{{index}}.</span>
           {{removeHtmlToQuestionTitle}}
        </h1>
        <div class="ChartMonitor--Comments" v-show="question.comments != undefined && question.comments.length > 0 && disableActions">
          <div>
            <span><i class="far fa-comment-dots"></i></span>
            <div>
              <p v-html="question.comments"></p>
              <span><i class="far fa-comment-dots"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!isQuestionLoaded" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <div v-show="isQuestionLoaded">
        <div class="ChartMonitor--Media" v-if="question.images || question.video">
          <p v-show="!showMultiMedia" v-on:click="showMultiMedia = true">{{ $t('watmonitor_show_multimedia') }}</p>
          <p v-show="showMultiMedia" v-on:click="showMultiMedia = false">{{ $t('watmonitor_hide_multimedia') }}</p>
          <transition name="transitionMedia">
            <div v-show="showMultiMedia">
              <MediaViewer v-if="question.images" class="ChartMonitor--MediaViewer" :images="$filters.formatChartMedia(question.images)"/>
              <MediaViewer v-if="question.video" class="ChartMonitor--MediaViewer"  :youTubeVideo="$filters.formatChartMedia([question.video])"/>
            </div>
          </transition>
        </div>
        <div v-if="showHeatMap" class="ChartMonitor--Heatmap" :id="'Heatmap--'+_uid">
          <div class="ChartMonitor--HeatmapSelection">
            <p v-for="(img,index) of question.images" :class="{'selected':index == currentHeatMap}" v-on:click="changeHeatMap(index)">{{$t('watmonitor_image')}} {{index + 1}}</p>
          </div>
          <HeatMapResults :key="heatmapKey" :ref="'HeatMapResults-'+_uid" :answersNum="1" :image="getHeatMapImageByIndex(currentHeatMap)" :heatMapAnswers="getHeatMapAnswersByIndex(currentHeatMap)" :heatmapPointDefinitions="getHeatMapPointsDefinitionByIndex(currentHeatMap)"/>
        </div>
        <div
          v-show="showChart"
          :id="'MonitorHighchart_'+_uid"
          class="ChartMonitor--Chart"
          :class="{'ChartMonitor--ChartGeographic':question.taskNum == -1 && question.questionId == 1}"
          :width="'100%'"
          ref="chart">
        </div>
        <div class="ChartMonitor--Chart ChartMonitor--ChartList" :class="{'ChartMonitor--ChartAnalysis':hasAnalysis}" v-if="showList">
          <div v-if="hasAnalysis" v-on:click="showAnalysis = !showAnalysis" class="ChartMonitor--AnalysisHeader" :class="{'selected':showAnalysis}">
            <span></span>
            <button>Analisis IA</button>
            <button>Respuestas originales</button>
          </div>
          <div v-show="showAnalysis" class="ChartMonitor--AnalysisContent" v-html="chartAnalysis">
          </div>
          <div v-show="!showAnalysis" class="ChartMonitor--SearchInput">
            <input v-if="question.questionType != 'MULTITEXT' && question.questionType != 'IMAGE_URL'" type="text" :placeholder="$t('watmonitor_find_answers')" v-model="question.textDimension.text" v-on:keyup="handlePageChange(pagination)">
            <input v-if="question.questionType == 'MULTITEXT'" type="text" :placeholder="$t('watmonitor_find_answers')" v-model="question.textDimension.multitext" v-on:keyup="handlePageChange(pagination)">
            <i class="fa fa-search"></i>
          </div>
          <div v-show="!showAnalysis" class="ChartMonitor--ChartList--Scroll">
            <div class="" v-show="question.questionType != 'MULTITEXT'" v-for="(t,textIndex) in question.chart.texts">
              <div v-if="question.chart.total == 0">
                <p>{{ $t('watmonitor_no_data') }}</p>
              </div>
              <div v-for="t2 in t" v-if="t.length > 0">
                <p v-show="question.chart.yLabels.length>1" style="margin:10px 0;border-bottom: 1px solid lightgrey;"> - {{question.chart.yLabels[textIndex]}}</p>
                <p v-for="(t3,i) in t2" v-if="question.questionType != 'IMAGE_URL'"><span class="textAnswerIndex">{{ (pagination - 1) * 10 + i + 1 }}.</span>{{t3}}</p>
                <div v-if="question.questionType == 'IMAGE_URL'" class="ChartMonitor--ChartList--Viewer">
                  <MediaViewer :images="$filters.formatChartMedia(t2)"/>
                </div>
              </div>
            </div>
            <div class="" v-if="question.questionType == 'MULTITEXT'" v-for="t in question.chart.multitexts">
              <div v-if="question.chart.total == 0">
                <p>{{ $t('watmonitor_no_data') }}</p>
              </div>
              <div v-for="t2 in t" v-if="t.length > 0">
                <div v-for="t3 in t2">
                  <p v-for="(t4,i) in t3"><span class="textAnswerIndex">{{i+1}}.</span>{{t4}}</p>
                  <hr>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            v-if="pageNum > 1 && !showAnalysis"
            v-model="pagination"
            :records="this.question.chart.total"
            :per-page="10"
             :options="{ texts: paginationTexts }"
            @paginate="handlePageChange" />
        </div>
        <div class="ChartMonitor--Chart ChartMonitor--ChartTable" v-if="showTable">
          <table :id="'table-copy_'+_uid" class="table table-chart" v-if="chartType == 'PERCENT_TABLE'" :key="tableKeys.percent">
            <tr class="table-chart__header">
              <td class="copy-table">
                <div data-title="Copiar tabla"><i v-on:click="copyTable()" class="fa fa-copy"></i></div>
                <div class="copy-table-message" style="display:none;">
                  {{ $t('watmonitor_copy_table_text') }}
                  <img src="/assets/img/report-v2/finished-icon.png" alt="">
                </div>
              </td>
              <th v-for="x in question.chart.xLabels">{{x}}</th>
              <th>{{$t('watmonitor_total')}}</th>
            </tr>
            <tr v-for="(y,i) in question.chart.yLabels">
              <th>{{y}}</th>
              <td v-for="v in question.chart.values[i]">{{v | formatNumber}}%</td>
              <td>{{question.chart.xTotals[i] | formatNumber}}</td>
            </tr>
            <tr>
              <th>{{$t('watmonitor_total')}}</th>
              <td v-for="v in question.chart.yTotals">{{v | formatNumber}}%</td>
              <td>{{question.chart.total | formatNumber}}</td>
            </tr>
          </table>
          <table :id="'table-copy_'+_uid" class="table table-chart" v-if="chartType == 'VALUE_TABLE' || chartType == 'AVERAGE_TABLE'" :key="tableKeys.value">
            <tr class="table-chart__header">
              <td class="copy-table">
                <div data-title="Copiar tabla"><i v-on:click="copyTable()" class="fa fa-copy"></i></div>
                <div class="copy-table-message" style="display:none;">
                  {{ $t('watmonitor_copy_table_text') }}
                  <img src="/assets/img/report-v2/finished-icon.png" alt="">
                </div>
              </td>
              <th v-for="x in question.chart.xLabels">{{x}}</th>
              <th>{{$t('watmonitor_total')}}</th>
            </tr>
            <tr v-for="(y,i) in question.chart.yLabels">
              <th>{{y}}</th>
              <td v-for="v in question.chart.values[i]">{{v}}</td>
              <td>{{question.chart.xTotals[i]}}</td>
            </tr>
            <tr>
              <th>{{$t('watmonitor_total')}}</th>
              <td v-for="v in question.chart.yTotals">{{v}}</td>
              <td>{{question.chart.total}}</td>
            </tr>
          </table>
        </div>
        <div v-if="showWatUx">
          <WatUxMonitor :i18n="i18n" :uxData="question.chart" :testId="test.test.identifier" :taskNum="taskNum"/>
        </div>
        <div class="ChartMonitor--Chart ChartMonitor--Label" v-if="showLabel">
          {{$t('watmonitor_question_no_answers')}}
        </div>
      </div>
    </div>
    <div class="ChartMonitor--ConfigBorder" v-show="showConfig">
      <div class="ChartMonitor--Config" v-if="question.chart && configType == 'CHART'">
        <MonitorMessage v-show="showMessage" :text="$t('watmonitor_info_personalization')" v-on:hide="showMessage = false" v-on:close="showMessage = false"/>
        <h1>Ordenación</h1>
        <OrderTypes
          :key="orderKey"
          :orderType="question.order"
          :graphType="question.graphType"
          :question="question"
          v-on:changed="changeOrderType($event)"
          :changeAxis="question.axisSwitched"
          :showChangeAxis="changeAxisEnabled()"
          v-on:changedAxis="changedAxis($event)"/>
        <h1>Visualización</h1>
        <ChartTypes :chartType="chartType" :question="question" v-on:changed="changeChartType($event)"/>
      </div>
      <div class="ChartMonitor--Config" v-if="question.chart && configType == 'VALUE'">
        <MonitorMessage v-show="showMessage" :text="$t('watmonitor_info_personalization')" v-on:hide="showMessage = false" v-on:close="showMessage = false"/>
        <h1>Título</h1>
        <input type="text" :placeholder="$t('watmonitor_default_title')" v-model="question.questionTextChanged">
        <h1>Valores eje X</h1>
        <div class="ChartMonitor--ConfigScroll">
          <input type="text" v-for="(v,i) of xLabels" v-model="xLabels[i]">
        </div>
        <h1>Valores eje Y</h1>
        <div class="ChartMonitor--ConfigScroll">
          <input type="text" v-for="(v,i) of yLabels" v-model="yLabels[i]">
        </div>
        <h1 v-show="(question.questionType == 'NUMBER' || question.questionType == 'MULTINUMBER') && this.chartType != 'LIST' && this.chartType != 'AVERAGE'">{{ $t('watmonitor_ranges') }}</h1>
        <MultiRange
          class="ChartMonitor--MultiRange"
          v-if="(question.questionType == 'NUMBER' || question.questionType == 'MULTINUMBER') && this.chartType != 'LIST' && this.chartType != 'AVERAGE'"
          v-on:send-values="getRangeValues"
          :modelValues="ranges"
          :min="question.numMin != undefined ? question.numMin : 0 "
          :max="question.numMax != undefined ? question.numMax : 100 " />
        <p v-on:click="selectFilterQuestion(null)" v-if="question.questionType == 'MULTISELECTION' || question.questionType == 'SELECTION'">{{ $t('watmonitor_advanced_options') }}</p>
        <p v-on:click="restoreDefaultLabels()" class="">{{ $t('watmonitor_restore_texts') }}</p>
        <button type="button" class="commonButton" name="button" v-on:click="changeLabelsName()">{{ $t('watmonitor_action_apply') }}</button>
      </div>
      <div class="ChartMonitor--Config" v-if="question.chart && configType == 'COMMENT'">
        <h1>{{ $t('watmonitor_comments_add') }}</h1>
        <textarea v-model="question.comments"></textarea>
      </div>
    </div>
    <div class="ChartMonitor--Actions" v-show="question.questionType != 'LABEL' && !disableActions">
      <div class="ChartMonitor--Actions--Box ChartMonitor--Exporting" v-show="chartType != 'LIST' && chartType.indexOf('TABLE') < 0 && question.questionType != 'WATUX'" v-on:click="showExportOptions = !showExportOptions">
        <i class="fas fa-cloud-download-alt"></i>
        <div class="" v-show="showExportOptions">
          <p v-on:click="downloadChart('PNG')">{{$t('watmonitor_download')}} PNG</p>
          <p v-on:click="downloadChart('JPG')">{{$t('watmonitor_download')}} JPG</p>
          <p v-on:click="downloadChart('SVG')">{{$t('watmonitor_download')}} SVG</p>
        </div>
        <p class="ChartMonitor--Actions--Box--Tooltip"><span>{{$t('watmonitor_export')}}</span></p>
      </div>
      <div class="ChartMonitor--Actions--Box" v-on:click="openSelectionModal()">
        <img src="../../assets/img/WatMonitor/actionFilters.svg">
        <p class="ChartMonitor--Actions--Box--Tooltip"><span>{{$t('watmonitor_filters')}}</span></p>
      </div>
      <div class="ChartMonitor--Actions--Box" v-on:click="openCloseConfig('COMMENT')" v-show="allowComments">
        <i aria-hidden="true" class="far fa-comment-dots"></i>
        <p class="ChartMonitor--Actions--Box--Tooltip"><span>{{$t('watmonitor_comments')}}</span></p>
      </div>
      <div class="ChartMonitor--Actions--Box" v-on:click="openCloseConfig('VALUE')" v-show="question.questionType != 'WATUX'">
        <img src="../../assets/img/WatMonitor/actionPencil.svg">
        <p class="ChartMonitor--Actions--Box--Tooltip"><span>{{ $t('watmonitor_edit_values') }}</span></p>
      </div>
      <div class="ChartMonitor--Actions--Box" v-on:click="openCloseConfig('CHART')" v-show="question.questionType != 'WATUX'">
        <img src="../../assets/img/WatMonitor/actionChart.svg">
        <p class="ChartMonitor--Actions--Box--Tooltip"><span>{{$t('watmonitor_visualization')}}</span></p>
      </div>
      <div class="ChartMonitor--Actions--Box" v-show="showConfig" v-on:click="openCloseConfig()">
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.379396 0.171369C0.151326 0.399438 0.151326 0.768106 0.379396 0.996175L6.38352 7.00032L0.379395 13.0044C0.151326 13.2325 0.151326 13.6012 0.379395 13.8293C0.607465 14.0573 0.976133 14.0573 1.2042 13.8293L7.62074 7.41271C7.73449 7.29897 7.79166 7.14965 7.79166 7.0003C7.79166 6.85095 7.73449 6.70163 7.62074 6.58788L1.2042 0.171341C0.976133 -0.0567017 0.607465 -0.0567007 0.379396 0.171369Z" fill="#404040"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, inject, onMounted } from 'vue';
import { mount } from '@/utils/mount'
import vueApp from '@/main';
import Pagination from 'v-pagination-3';
import MultiRange from './MultiRange'
import QuestionIMG from '~/components/Question/QuestionIMG'
import ChartTypes from './ChartTypes'
import OrderTypes from './OrderTypes'
import FilterTag from './FilterTag'
import MonitorMessage from './MonitorMessage'
import QuestionAdvancedModal from './QuestionAdvancedModal'
import QuestionSelectionModal from './QuestionSelectionModal'
import WatUxMonitor from './WatUxMonitor'
import MediaViewer from '~/components/MediaViewer'
import HeatMapResults from '~/components/HeatMap/HeatMapResults'
import Exporting from "highcharts/modules/exporting";
import OfflineExporting from "highcharts/modules/offline-exporting";
import Highcharts from "highcharts";
import Swal from 'sweetalert2'
import { getBase64Image, getGoogleFontURI, externalUrlToBase64 } from '../../utils/watmonitor/chart'
import { removeHTML} from '~/utils/watmonitor/'

import store from '@/store'
Exporting(Highcharts);
OfflineExporting(Highcharts);
export default {
  name: 'Chart',

  props: {
    i18n:Object,
    question:Object,
    index:Number,
    personalization:Object,
    filters:Array,
    questionsToFilter:Array,
    companyFilters:Array,
    reportGlobalFilters:Array,
    test:Object,
    taskNum: Number,
    disableActions:{
      type:Boolean,
      default:false,
    },
    allowComments:{
      type:Boolean,
      default:true
    },
    showSocioDemographics:{
      type:Boolean,
      default:true
    },
    nielsen:Array,
    regions:Array,
    hideQuestionIndex:{
      type: Boolean,
      default: false
    },
    isQuestionLoaded: Boolean
  },
  components: {
    QuestionIMG,
    Pagination,
    ChartTypes,
    OrderTypes,
    FilterTag,
    MonitorMessage,
    QuestionAdvancedModal,
    QuestionSelectionModal,
    MediaViewer,
    MultiRange,
    HeatMapResults,
    WatUxMonitor
  },
  computed:{
    hasAnalysis(){
      const analysis = this.question.chart.analysis
      return analysis != undefined && analysis != null && analysis.length > 0
    },
    chartAnalysis(){
      if(this.question.chart.analysis == null || this.question.chart.analysis == undefined) return ""
      const text = this.question.chart.analysis.replace(/\n/g, "<br>")
      return text
    },
    removeHtmlToQuestionTitle(){
        return this.question.questionText = removeHTML(this.question.questionText)
    },
    paginationTexts() {
      const locale = this.$i18n.locale;
      // Accedemos directamente al mensaje sin pasar por el método $t,
      // para obtener la cadena literal con los placeholders.
      const messages = this.$i18n.messages[locale];
      return {
        count: messages.pagination.texts.count, // "Mostrando {from} a {to} de {count} registros"
        first: messages.pagination.texts.first,
        last: messages.pagination.texts.last
      }
    }

    
  },
  data() {
    return {
      heatmapKey:0,
      tableKeys:{
        value:0,
        percent:0,
      },
      currentHeatMap:0,
      orderKey:0,
      fontFamily:'Lato',
      Highcharts: Highcharts,
      chartConfig:{
        colors:["#8134ff","#FF1564","#FD7328","#361466","#895AD2","#96C800","#FD4D40","#f9c39f","#ede8f2","#669d98","#a81187","#8aefbb"],
        legend:{
          enabled: true,
          maxHeight: 100,
          // navigation:{
          //   enabled: false,
          // }
        },
        chart: {
            type: 'column',
            style:{
              fontFamily: 'Lato'
            }
        },
        boost: {
          useGPUTranslations: true,
          seriesThreshold: 1
        },
        title: {
            text: '',
        },
        subtitle: {
          text: 'Número de encuestados : 100',
          align: 'right',
          style:{"font-weight":"bold","font-size":"13px","color":"#333333"}
        },
        xAxis: {
            categories: []
        },
        yAxis: {
            allowDecimals: false,
            min: 0,
            max:undefined,
            title: {
                text: 'Número de Testers'
            }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            groupPadding: 0.05,
            dataLabels: {
               enabled: true,
               format: '{point.y:.1f}%',
             }
          },
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            }
          },
          bar:{
            allowPointSelect: true,
            borderWidth: 0,
            pointPadding: 0.2,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '{point.y:.1f}',
            }
          },
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>'
        },
        series: [],
        exporting: {
           enabled: false,
           sourceWidth: 600
        },
        credits: {
            enabled: false
        },
      },
      chart:{},
      pageNum:0,
      pagination:1,
      showWatUx: false,
      showChart:true,
      showHeatMap:false,
      showList:false,
      showTable:false,
      showLabel:false,
      showConfig:false,
      showMessage:true,
      configType:null,
      advancedModalQuestion:{},
      showExportOptions:false,
      chartTxtSearch:"",
      value:1,
      ranges:[],
      values:[],
      groups:[],
      chartType:"COLUMN",
      isFirstChartGeneration:true,
      basicFilters:[],
      questionFilters:[],
      xLabels:[],
      yLabels:[],
      applyPreviousLabels:false,
      geographicSelected:1,
      chartTitleBackUp:'',
      modalBreadcrumbs:true,
      showMultiMedia:false,
      SelectionModalInstance: null,
      AdvancedModalInstance: null,
      showAnalysis: false,
      _uid:getCurrentInstance().uid
    }
  },
  filters:{
    formatNumber: function(number){
      return number.toFixed(2).replace(".",",")+"%"
    },
  },
  methods:{
    getQuestionTextNum(qId, taskNum){
      const q = this.$parent.getQuestionByIdAndTaskNum(qId, taskNum)
      if(q == undefined || q == null ) return 0
      else return q.textNum
    },
    changeHeatMap(index){
      this.currentHeatMap = index
      this.heatmapKey ++
      this.$refs['HeatMapResults-'+this._uid].reloadHeatMap()
    },
    setChartResizeObserver(){
      let vm = this
      const resizeObserver = new ResizeObserver(function(entries) {
        let rect = entries[0].contentRect;
        let width = rect.width;
        let height = rect.height;
        if(vm.chartType != "LIST" && vm.question.questionType != 'LABEL' && vm.question.questionType != 'HEATMAP' && vm.question.questionType != 'WATUX' && Object.keys(vm.chart).length > 0){
          vm.chart.setSize(width, height);
        }
      });
      resizeObserver.observe(document.querySelector('#MonitorHighchart_'+this._uid));
    },
    openCloseConfig(configType){
      if(configType == 'VALUE') this.setRangesArray()
      this.configType = configType != undefined ? configType : null
      if(this.question.questionType != 'LABEL'){
        this.showConfig = configType != undefined ? true : false
      }
    },
    restoreDefaultLabels(){
      this.question.questionText = this.chartTitleBackUp
      this.question.questionTextChanged = null
      this.$emit('fetchQuestion',{chartType:this.chartType,question:this.question,values:this.values,groups:this.groups,value:this.value})
    },
    async changeLabelsName(){
      this.question.questionText = this.question.questionTextChanged != null && this.question.questionTextChanged != undefined && this.question.questionTextChanged != '' ? this.question.questionTextChanged : this.question.questionText
      if((this.question.questionType == 'NUMBER' || this.question.questionType == 'MULTINUMBER') && this.chartType != "AVERAGE" && this.chartType != "LIST"){
        this.applyPreviousLabels = this.xLabels.length == this.question.ranges.length && this.question.ranges[0].to == 100
        this.$emit('fetchQuestion',{chartType:this.chartType,question:this.question,values:this.values,groups:this.groups,value:this.value})
      }
      else{
        this.question.chart.xLabels = this.xLabels
        this.question.chart.yLabels = this.yLabels
        this.generate()
      }
    },
    changeGeographicChart(geographicSelected){
      if(this.geographicSelected == geographicSelected) return
      this.geographicSelected = geographicSelected
      this.$emit('fetchQuestion',{chartType:this.chartType,question:this.question,values:this.values,groups:this.groups,value:this.value})
    },
    changeMultinumberChart(value){
      if(this.value == value) return
      this.value = value
      this.$emit('fetchQuestion',{chartType:this.chartType,question:this.question,values:this.values,groups:this.groups,value:this.value})
    },
    generate(){
      if (this.question.chart == undefined) {
        setTimeout(async () => {
          await this.generate();
        }, 2000);
      } else {
        this.fontFamily = this.personalization.font != '' ? this.personalization.font : this.chartConfig.chart.style.fontFamily
        if(this.question.questionType == "WATUX"){
          this.showChart = false
          this.showList = false
          this.showTable = false
          this.showLabel = false
          this.showHeatMap = false
          this.showWatUx = true
          return
        }
        if(this.question.questionType == "HEATMAP"){
          this.showChart = false
          this.showList = false
          this.showTable = false
          this.showLabel = false
          this.showHeatMap = true
          this.showWatUx = false
          return
        }
        if(this.question.questionType == "LABEL"){
          this.showChart = false
          this.showList = false
          this.showTable = false
          this.showLabel = true
          this.showHeatMap = false
          this.showWatUx = false
          return
        }
        if(this.isFirstChartGeneration){
          this.setDefaultChart()
          this.setChartFilters()
          this.chartTitleBackUp = this.question.questionText
          if(this.regions.length == 0) this.geographicSelected = 0
        }
        if(this.chartType == "LIST"){
          this.generateList()
          this.showChart = false
          this.showList = true
          this.showTable = false
          this.showHeatMap = false
          this.showWatUx = false
          return
        }
        if(this.chartType == "VALUE_TABLE" || this.chartType == "PERCENT_TABLE" || this.chartType == "AVERAGE_TABLE"){
          this.showChart = false
          this.showList = false
          this.showTable = true
          this.showHeatMap = false
          this.showWatUx = false
          this.tableKeys.value ++
          this.tableKeys.percent ++
          return
        }
        this.showChart = true
        this.showList = false
        this.showTable = false
        this.showHeatMap = false
        this.showWatUx = false
        this.chartConfig.colors = this.personalization.colors && this.personalization.colors.length > 0 ? this.personalization.colors : this.chartConfig.colors
        this.chartConfig.chart.style.fontFamily = this.personalization.font != '' ? this.personalization.font : this.chartConfig.chart.style.fontFamily
        if(this.applyPreviousLabels){
          this.question.chart.xLabels = this.xLabels
          this.question.chart.yLabels = this.yLabels
        }
        else{
          this.applyPreviousLabels = false
          /* if(this.question.xAxisValues && this.question.xAxisValues.length > 0) this.question.chart.xLabels = this.question.xAxisValues */
          this.xLabels = this.question.chart != undefined ?  this.question.chart.xLabels : []
          this.yLabels = this.question.chart != undefined ?  this.question.chart.yLabels : []
        }
        if(this.question.resultType == "VALUE"){
          this.chartConfig.tooltip.pointFormat = '<span style="color:{point.color}">{point.name}</span><b>{point.y}</b><br/>'
          this.chartConfig.yAxis.title.text = this.$t(`watmonitor_chart_axis_title.${this.question.yAxisTitle}`)
          this.chartConfig.plotOptions.column.dataLabels.format = '{point.y}'
        }
        else{
          this.chartConfig.tooltip.pointFormat = '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> del total<br/>'
          this.chartConfig.yAxis.title.text = this.$t(`watmonitor_chart_axis_title.${this.question.yAxisTitle}`)
          this.chartConfig.plotOptions.column.dataLabels.format = '{point.y:.1f}%'
        }
        this.chartConfig.subtitle.text = "Número de encuestados : " + this.question.chart.answersNum
        this.chartConfig.xAxis.categories = this.question.chart.yLabels
        this.chartConfig.chart.inverted = this.question.graphType == "HORIZONTAL_BARS" ? true : false
        if(this.chartType == "AVERAGE"){
          this.chartConfig.chart.type = 'column'
          this.chartConfig.yAxis.title.text = this.$t(`watmonitor_chart_axis_title.${this.question.yAxisTitle}`)
          this.chartConfig.plotOptions.column.stacking = null
          this.chartConfig.plotOptions.column.dataLabels.enabled = true
          this.chartConfig.plotOptions.column.dataLabels.format = '{point.y:.1f}'
          this.chartConfig.tooltip.pointFormat = '<span style="color:{point.color}">{point.name}</span><b>{point.y:.2f}</b><br/>'
          // this.chartConfig.yAxis.min = question.rankingMin
          // this.chartConfig.yAxis.max = question.rankingMax
        }
        else if(this.chartType == "COLUMN"){
          this.chartConfig.chart.type = 'column'
          this.chartConfig.plotOptions.column.stacking = null
          this.chartConfig.plotOptions.column.dataLabels.enabled = true
        }
        else if(this.chartType == "PIE" || this.chartType == "RANGES_PIE"){
          this.chartConfig.chart.type = 'pie'
        }
        else if(this.chartType == "STACKED_BARS"){
          this.chartConfig.chart.type = 'column'
          this.chartConfig.plotOptions.column.stacking = "percent"
        }
        if(this.isFirstChartGeneration){
          if(this.chartType == 'PIE') this.generatePieValues()
          else this.generateValues()
          this.chart = this.Highcharts.chart('MonitorHighchart_'+this._uid,this.chartConfig)
        }
        else{
          for(var i = this.chart.series.length - 1; i > -1; i--) {
            this.chart.series[i].remove(false);
          }
          this.chart.update(this.chartConfig)
          if(this.chartType == 'PIE') this.generatePieValues()
          else this.generateValues()
          this.chart.redraw(true)
        }
        this.chart.reflow()
        this.isFirstChartGeneration = false
        // if(this.isMultiselectionFilterError()) this.chart.showLoading('Actualmente el filtrado entre dos preguntas de selección multiple no está disponible, estamos trabajando para tenerlo disponible');
        // else this.chart.hideLoading()
        this.chart.hideLoading()
      }
    },
    generatePieValues(){
      let values = []
      for(let x in this.question.chart.xLabels){
        values.push({name:this.question.chart.xLabels[x],y:this.question.chart.values[0][x]})
      }
      if(this.isFirstChartGeneration) this.chartConfig.series.push({name:this.question.chart.yLabels[0],colorByPoint:true,data:values})
      else this.chart.addSeries({name:this.question.chart.yLabels[0],colorByPoint:true,data:values},false)
    },
    generateValues(){
      for(let x in this.question.chart.xLabels){
        let values = []
        for(let i in this.question.chart.values){
          values.push(this.question.chart.values[i][x])
        }
        if(this.isFirstChartGeneration) this.chartConfig.series.push({name:this.question.chart.xLabels[x],data:values})
        else this.chart.addSeries({name:this.question.chart.xLabels[x],data:values},false)
      }
    },
    generateList(){
      this.pageNum = Math.floor(this.question.chart.total / 10)
      let needsOnePageMore = (this.question.chart.total % 10)>0
      if (needsOnePageMore){
          this.pageNum++;
      }
    },
    handlePageChange(e){
      this.question.page = e-1
      this.$emit('changePage',{page:e-1,question:this.question,chartType:this.chartType})
    },
    changeChartType(e){
      this.chartType = e
      this.question.graphType = this.chartType
      //if(this.chartType == "COLUMN" || this.chartType == "AVERAGE") this.question.graphType = 'HORIZONTAL_BARS'
      this.$emit('fetchQuestion',{chartType:e,question:this.question,values:this.values,groups:this.groups,value:this.value})
    },
    changeOrderType(e){
      this.question[e.index] = e.value
      this.orderKey++
      if(e.index == 'order')this.$emit('fetchQuestion',{chartType:this.chartType,question:this.question,values:this.values,groups:this.groups,value:this.value})
      else this.generate()
    },
    changedAxis(e){
      this.question.axisSwitched = e
      this.$emit('fetchQuestion',{chartType:this.chartType,question:this.question,values:this.values,groups:this.groups,value:this.value})
    },
    changeAxisEnabled(){
      if(this.question.yDimensions != null &&
        this.question.yDimensions != undefined &&
        this.question.yDimensions.length > 0 &&
        this.question.xDimensions != null &&
        this.question.xDimensions != undefined &&
        this.question.xDimensions.length > 0) return true
      return false
    },
    setChartFilters(){
      this.basicFilters = []
      this.questionFilters = []
      this.values = this.$parent.getQuestionByIdAndTaskNum(this.question.questionId,this.question.taskNum).values
      for(let dimension of this.question.yDimensions){
        if(!this.isGlobalFilter(dimension.taskNum,dimension.questionNum)){
          let text = ""
          if(dimension.taskNum != -1 || dimension.questionNum >=3){
            for(let f of this.questionsToFilter){
              if(f.id == dimension.questionNum) text = f.text
            }
          }
          let filter = {
            questionText:text,
            questionNum:dimension.questionNum,
            taskNum:dimension.taskNum,
            values:this.getSelectedValues(dimension.taskNum == -1 && dimension.questionNum <=3 ? this.filters : this.questionsToFilter,dimension.questionNum,dimension.values),
            ranges:dimension.ranges,
          }
          if(dimension.taskNum == -1 && dimension.questionNum <=3) this.basicFilters.push(filter)
          else this.questionFilters.push(filter)
        }
      }
      for(let dimension of this.question.xDimensions){
        if(dimension.groups){
          this.groups = dimension.groups
          for(let g of this.groups){
            let values = []
            for(let v of this.values){
              if(g.values.includes(v.id)) values.push(v)
            }
            g.values = values
          }
        }
        if(dimension.values){
          for(let v of this.values){
            v.selected = dimension.values.includes(v.id)
          }
        }
      }
    },
    getSelectedValues(filters,questionNum,values){
      if(values == undefined || values == null) return undefined
      let selectedValues = []
      for(let f of filters){
        if(f[f.taskNum == -1 && f.questionNum != undefined ? 'questionNum' : 'id'] == questionNum){
          selectedValues = JSON.parse(JSON.stringify(f.values))
        }
      }
      for(let v of selectedValues){
        if (values.includes(v.id)) v.selected = true
      }
      return selectedValues
    },
    isGlobalFilter(taskNum,questionNum){
      let returnedValue = false
      for(let f of this.reportGlobalFilters){
        if(f.taskNum == taskNum && f.questionNum == questionNum) returnedValue = true
      }
      return returnedValue
    },
    setDefaultChart(){
      if(this.question.graphType == "HORIZONTAL_BARS" || this.question.graphType == "VERTICAL_BARS"){
        if(this.question.resultType == 'AVERAGE') this.chartType = 'AVERAGE'
        else this.chartType = 'COLUMN'
      }
      else this.chartType = this.question.graphType
    },
    copyTable: function(){
      let table = document.getElementById("table-copy_"+this._uid);
      let body = document.body, range, sel
      range = document.createRange()
      sel = window.getSelection()
      sel.removeAllRanges()
      range.selectNodeContents(table)
      sel.addRange(range)
      let selected = window.getSelection().toString();
      document.execCommand("copy");
      sel.removeRange(range)
      $(".copy-table-message").show()
      setTimeout(() => {
        $(".copy-table-message").hide()
      }, 2000)
    },
    selectFilterQuestion(q){
      if(q == null){
        this.advancedModalQuestion = {isChartQuestion:true,questionText:this.question.questionText,questionNum:this.question.questionId,taskNum:this.question.taskNum,values:this.values,groups:this.groups}
        this.modalBreadcrumbs = false
        return
      }
      else{
        if(q.taskNum == -1 && q.questionNum != undefined && q.questionNum <=3){
          for(let f of this.filters){
            if(f.questionNum == q.questionNum) {              
              this.advancedModalQuestion = {questionText:q.text,questionNum:q.questionNum,taskNum:q.taskNum,values:f.values, ranges:q.questionType == 'NUMBER' ? [{from:16,to:100}] : [], groups:[]}
            }
          }
        }
        else{
          this.advancedModalQuestion = {questionText:q.text,questionNum:q.id,taskNum:q.taskNum,values:q.values, groups:[]}
        }
      }
      this.modalBreadcrumbs = true
    },
    saveFilters(event){
      let filterAlreadyExists = false
      this.advancedModalQuestion.values = event.values
      this.advancedModalQuestion.groups = event.groups.length > 0 ? event.groups : undefined
      this.advancedModalQuestion.ranges = event.ranges.length > 0 ? event.ranges : undefined
      if(this.advancedModalQuestion.isChartQuestion){
        this.values = event.values
        this.groups = event.groups.length > 0 ? event.groups : undefined
      }
      else if(this.advancedModalQuestion.taskNum == -1 && this.advancedModalQuestion.questionNum <=3){
        for(let f of this.basicFilters){
          if(f.questionNum == this.advancedModalQuestion.questionNum) {
            f = this.advancedModalQuestion
            filterAlreadyExists = true
          }
        }
        if(!filterAlreadyExists) this.basicFilters.push(this.advancedModalQuestion)
      }
      else{
        for(let index in this.questionFilters){
          if(this.questionFilters[index].questionNum == this.advancedModalQuestion.questionNum) {
            this.questionFilters[index] = this.advancedModalQuestion
            filterAlreadyExists = true
          }
        }
        if(!filterAlreadyExists) this.questionFilters.push(this.advancedModalQuestion)
      }
      this.advancedModalQuestion = {}
      this.$emit('fetchQuestion',{chartType:this.chartType,question:this.question,values:this.values,groups:this.groups,value:this.value})
    },
    editFilter(filter){
      let filterToEdit = {}
      if(filter.taskNum == -1 && filter.questionNum <=3){
        for(let f of this.basicFilters){
          if(f.questionNum == filter.questionNum) {
            filterToEdit = f
          }
        }
      }else{
        for(let f of this.questionFilters){
          if (filter.questionNum != undefined) {
            if(f.questionNum == filter.questionNum) {
              filterToEdit = f
            }
          } else {
            if(f.questionNum == filter.id) {
              filterToEdit = f
            }
          }
        }
      }
      this.advancedModalQuestion = filterToEdit
      this.modalBreadcrumbs = false
      this.createAdvancedOptionsInstance()
    },
    removeFilter(filter){
      if(filter.isQuestion){
        for(let i in this.questionFilters){
          if(this.questionFilters[i].questionNum == filter.questionNum && this.questionFilters[i].taskNum == filter.taskNum) this.questionFilters.splice(i,1)
        }
      }
      else{
        for(let i in this.basicFilters){
          if(this.basicFilters[i].questionNum == filter.questionNum && this.basicFilters[i].taskNum == filter.taskNum) this.basicFilters.splice(i,1)
        }
      }
      if(this.getFilterFormated().length == 0 && this.question.axisSwitched) this.question.axisSwitched = false
      this.$emit('fetchQuestion',{chartType:this.chartType,question:this.question,values:this.values,groups:this.groups,value:this.value})
    },
    getFilterFormated(){
      let data = []
      for(let f of this.basicFilters){
        if(f.questionNum == 1){
          let text = []
          for(let v of f.values){
            if(v.selected) text.push(this.$parent.getProvinceById(v.id).value)
          }
          if(text.length > 0) data.push({text:"PROVINCIA: "+text.toString(), questionNum:f.questionNum, taskNum:f.taskNum})
        }
        if(f.questionNum == 2){
          if(f.ranges.length > 0)data.push({text:"EDAD: "+f.ranges.map(function(r,i){return r.from+"-"+r.to}).toString(), questionNum:f.questionNum, taskNum:f.taskNum})
        }
        if(f.questionNum == 3){
          let text = []
          if(f.values[0].selected) text.push("Hombre")
          if(f.values[1].selected) text.push("Mujer")
          if(text.length > 0) data.push({text:'GÉNERO: '+text.toString(), questionNum:f.questionNum, taskNum:f.taskNum})
        }
      }
      for(let f of this.questionFilters){
        if(f.values){
          let added = false
          for(let v of f.values){
            if(v.selected) added = true
          }
          let text = f.questionNum + ". " + f.questionText
          if(added)data.push({text:text, questionNum:f.questionNum, taskNum:f.taskNum, isQuestion:true})
        }
      }
      return data
    },
    changePersonalization(){
      this.fontFamily = this.personalization.font
      this.chartConfig.colors = this.personalization.colors.length > 0 ? this.chartConfig.colors : ["#8134ff","#FF1564","#FD7328","#361466","#895AD2","#96C800","#FD4D40","#f9c39f","#ede8f2","#669d98","#a81187","#8aefbb"]
      this.chartConfig.chart.style.fontFamily = this.personalization.font
      if(this.question.questionType != 'LABEL' && this.chartType != "LIST" && !this.chartType.includes('TABLE')) this.generate()
    },
    getRangeValues(e){
      this.question.ranges = e
    },
    setRangesArray(){
      this.ranges = []
      for(let i in this.question.ranges){
        if(i == 0) this.ranges.push(this.question.ranges[i].from)
        this.ranges.push(this.question.ranges[i].to)
      }
    },
    async getLogoToDownload(){
      let src = document.querySelector('#QuestionImage_' + this._uid + ' img').src;
      src = src.substring(src.indexOf('/img/')).replace('/img/','')
      src= src.substring(0,src.indexOf('.'))
      const img = require('~/assets/img/QuestionTypes/'+src+'.svg')
      return await getBase64Image(img)
    },
    getTitleHeight(text){
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = "bold 19px Lato";
      const metrics = context.measureText(text);
      const height = Math.ceil(metrics.width / 605) * (metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent)
      return height;
    },
    async downloadChart(type){
      if(this.question.questionType == 'HEATMAP') return this.downloadChartHeatMap(type)
      var vm = this;
      const font = await getGoogleFontURI()
      const logoBase64 = await this.getLogoToDownload()
      Swal.fire({
        title: '¿Estás seguro?',
        text: "Se va a descargar el archivo",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, descargar',
        cancelButtonText: 'Cancelar'

      }).then((result) => {
        if(!result.isConfirmed) return
        const logo = '<image href="'+logoBase64+'" height="31px" width="28px" transform="translate(0,10)"/>'
        const title = vm.index + ". " + vm.question.questionText
        const svgFont = '<defs><style type="text/css">@font-face{font-family:'+vm.fontFamily+';src:url('+font+') format("woff2")}</style></defs>'
        let chartTitle = "<switch transform='translate(50,-10)'><foreignObject  width='522' height='100%'><p xmlns='http://www.w3.org/1999/xhtml' style='font-family:"+vm.fontFamily+";font-weight: bold;font-size: 19px;letter-spacing: 0.01em;'>"+title+"</p></foreignObject></switch>"
        chartTitle = chartTitle.replace('&nbsp;', ' ');
        const chartHeightPosition = vm.getTitleHeight(title)
        let chart = vm.chart.getSVG()
        chart = chart.replace('<svg', '<g transform="translate(0,'+(60+chartHeightPosition)+')" ')
        chart = chart.replace('</svg>', '</g>');

        let svgExport = '<svg height="' + (500 + chartHeightPosition) + '" width="600" version="1.1" xmlns="http://www.w3.org/2000/svg">' + svgFont+ logo+chartTitle+chart + '</svg>'
        var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(svgExport);
        if(type == 'SVG') vm.finishChartDownload(url,vm.index+". "+vm.question.questionText+".svg")
        if(type == 'PNG') vm.downloadChartImage(type,url,chartHeightPosition)
        if(type == 'JPG') vm.downloadChartImage(type,url,chartHeightPosition)
      })
    },
    getHeatMapHeight(img){
    	const imgWidth = img.width
      const imgHeight = img.height
      const basicWidth = 600
      const restWidth = basicWidth - imgWidth
      const percentWidth = (restWidth / imgWidth) * 100
      const h = (imgHeight * percentWidth) / 100
      return imgHeight + h
    },
    async downloadChartHeatMap(type){
      const font = await getGoogleFontURI()
      const logoBase64 = await this.getLogoToDownload()
      const imageBlobUrl = await externalUrlToBase64(document.querySelector('#Heatmap--' + this._uid + ' img').src)
      let height = null
      let image = new Image();
      let vm = this
      image.onload = function(e) {
        height = vm.getHeatMapHeight(image)
        const logo = '<image href="'+logoBase64+'" height="31px" width="28px" transform="translate(0,10)"/>'
        const title = vm.index + ". " + vm.question.questionText + " ( "+vm.$refs['HeatMapResults-'+vm._uid].getCurrentHeatMapName()+" )"
        const svgFont = '<defs><style type="text/css">@font-face{font-family:'+vm.fontFamily+';src:url('+font+') format("woff2")}</style></defs>'
        let chartTitle = "<switch transform='translate(50,-10)'><foreignObject  width='522' height='100%'><p xmlns='http://www.w3.org/1999/xhtml' style='font-family:"+vm.fontFamily+";font-weight: bold;font-size: 19px;letter-spacing: 0.01em;'>"+title+"</p></foreignObject></switch>"
        chartTitle = chartTitle.replace('&nbsp;', ' ');
        const chartHeightPosition = vm.getTitleHeight(title)
        const chart = '<image href="'+imageBlobUrl+'" height="' + height + '" width="600" transform="translate(10,' + (60+chartHeightPosition) + ')"/>'
        const canvasHeatMapBase64 = vm.$refs['HeatMapResults-'+vm._uid].getDataUrlFake()
        const canvasHeatMap = '<image href="'+canvasHeatMapBase64+'" height="' + height + '" width="600" transform="translate(10,' + (60+chartHeightPosition) + ')"/>'

        //--------------- TM
        let svgExport = '<svg height="' + (500 + chartHeightPosition + height) + '" width="600" version="1.1" xmlns="http://www.w3.org/2000/svg">' + svgFont+ logo+chartTitle+chart+canvasHeatMap + '</svg>'
        var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(svgExport);
        if(type == 'SVG') vm.finishChartDownload(url,vm.index+". "+vm.question.questionText+".svg")
        if(type == 'PNG') vm.downloadChartImage(type,url,chartHeightPosition + height)
        if(type == 'JPG') vm.downloadChartImage(type,url,chartHeightPosition + height)
      };
      image.src = imageBlobUrl
    },
    async downloadChartImage(type,url,chartHeightPosition){
      let image = new Image();
      let canvas = document.createElement('canvas');
      let vm = this
      image.onload = function(e) {
        canvas.width = 605;
        canvas.height = 505 + chartHeightPosition;
        let context = canvas.getContext('2d');
        context.fillStyle = "rgb(255,255,255)";
        context.fillRect (0, 0, 605, 505 + chartHeightPosition );
        context.drawImage(image, 0, 0, 600, 500 + chartHeightPosition );
        let exportImage;
        if(type == 'JPG') {
          exportImage = canvas.toDataURL('image/jpeg');
        }else{
          exportImage = canvas.toDataURL();
        }
        vm.finishChartDownload(exportImage,vm.index+". "+vm.question.questionText+"."+type.toLowerCase());
      };
      image.src = url;
    },
    finishChartDownload(href,name){
      var downloadLink = document.createElement('a');
      downloadLink.href = href;
      downloadLink.target = "_blank"
      downloadLink.download = name;
      // downloadLink.style.opacity = "0";
      downloadLink.style.background = 'white'
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    openSelectionModal(){
      this.createSelectionModalInstance()
    },
    createSelectionModalInstance(){
      const { el, vNode, destroy } = mount(QuestionSelectionModal, {
        props: {
          title: 'Seleccionar pregunta',
          questions:this.questionsToFilter,
          currentQuestion:this.question,
          showSocioDemographics:this.showSocioDemographics
        },
        app: vueApp
      })
      vueApp._container.appendChild(el);
      this.SelectionModalInstance = {
        el: el,
        vNode: vNode,
        destroy: destroy
      }
    },
    createAdvancedOptionsInstance(){
      const { el, vNode, destroy } = mount(QuestionAdvancedModal, {
        props: {
          title: 'Opciones avanzadas',
          subTitle: this.advancedModalQuestion.questionNum+'. '+this.advancedModalQuestion.questionText,
          questions:this.questionsToFilter,
          filter:this.advancedModalQuestion,
          breadcrumbs:this.modalBreadcrumbs,
          breadcrumbsList:[{title:'Seleccionar pregunta'}],
        },
        app: vueApp
      })
      vueApp._container.appendChild(el);
      this.AdvancedModalInstance = {
        el: el,
        vNode: vNode,
        destroy: destroy
      }
    },
    isMultiselectionFilterError(){
      for(let dimension of this.question.yDimensions){
        let dimensionQuestionType = this.$parent.getQuestionByIdAndTaskNum(dimension.questionNum,dimension.taskNum).type
        if(dimensionQuestionType == 'MULTISELECTION' && this.question.questionType == 'MULTISELECTION' && (dimension.taskNum != this.question.taskNum || dimension.questionNum != this.question.questionId)) return true
      }
      for(let dimension of this.question.xDimensions){
        let dimensionQuestionType = this.$parent.getQuestionByIdAndTaskNum(dimension.questionNum,dimension.taskNum).type
        if(dimensionQuestionType == 'MULTISELECTION' && this.question.questionType == 'MULTISELECTION' && (dimension.taskNum != this.question.taskNum || dimension.questionNum != this.question.questionId)) return true
      }
      return false
    },
    getHeatMapImageByIndex(index){
      return this.question.images[index]
    },
    getHeatMapAnswersByIndex(index){
      let pointsByImageIndex = _.filter(this.question.chart.heatmapPoints, function (p) { return p.imageIndex == index });
      let data = {
        hmvaluesByType:{
          RED: _.filter(pointsByImageIndex, function (p) { return p.type == "RED" }),
          GREEN:_.filter(pointsByImageIndex, function (p) { return p.type == "GREEN" }),
        },
        hmvaluesByPoint:{}
      }
      let pointsByIndex = {}
      for(let point of pointsByImageIndex){
        if (pointsByIndex[point.identifier] == undefined) pointsByIndex[point.identifier] = []
        pointsByIndex[point.identifier].push(point)
      }
      let pointsByIndexOrdered = {}
      let pointsIndex = 0
      for (var [key, value] of Object.entries(pointsByIndex)) {
          pointsByIndexOrdered[pointsIndex] = value
          pointsIndex++
      }
      data.hmvaluesByPoint = pointsByIndexOrdered
      return data
    },
    getHeatMapPointsDefinitionByIndex(index){
      let data = _.filter(this.question.heatmapPointDefinitions, function (p) { return p.imageIndex == index });
      return data
    },
  },
  mounted(){
    this.setChartResizeObserver()
    const emitter = inject("emitter")
    let eventFilters = {}
    emitter.on("close-question-selection-modal", (event) => {
      if(this.SelectionModalInstance != null && this.SelectionModalInstance != undefined){
        this.SelectionModalInstance.destroy()
        this.SelectionModalInstance = null
      } 
    })
    emitter.on("select-question-selection-modal", (event) => {
      if(this.SelectionModalInstance != null && this.SelectionModalInstance != undefined){
        this.selectFilterQuestion(event)
        this.SelectionModalInstance.destroy()
        this.SelectionModalInstance = null

        let appliedFilters = []
        for (let ff of this.getFilterFormated()) {
          let filterText = ""
          if (ff.isQuestion != null || ff.isQuestion) {
            filterText = ff.text.split(". ")[1].trim().toLowerCase()
          } else {
            filterText = ff.text.split(":")[0].trim().toLowerCase()
          }
          if (!appliedFilters.includes(filterText)) {
            appliedFilters.push(filterText);
          }
        }

        if (this.getFilterFormated().length > 0 && appliedFilters.includes(event.text.toLowerCase())) {
          let filterTextFirst = ""
          let filterTextSecond = event.text.toLowerCase()
          for (let ff of this.getFilterFormated()) {
            if (ff.isQuestion != null || ff.isQuestion) {
              filterTextFirst = ff.text.split(". ")[1].trim().toLowerCase()
            } else {
              filterTextFirst = ff.text.split(":")[0].trim().toLowerCase()
            }
            if (filterTextFirst == filterTextSecond) {
              this.editFilter(event);
            }
          }
        } else {
          this.createAdvancedOptionsInstance()
        }
      }
    })
    emitter.on("close-monitor-advanced-filters-modal", (event) => {
      if(this.AdvancedModalInstance != undefined && this.AdvancedModalInstance != null) {
        this.AdvancedModalInstance.destroy()
        this.AdvancedModalInstance = null
      }
    })
    emitter.on("lastStep-monitor-advanced-filters-modal", (event) => {
      if(this.AdvancedModalInstance != undefined && this.AdvancedModalInstance != null){
        this.AdvancedModalInstance.destroy()
        this.AdvancedModalInstance = null
        this.createSelectionModalInstance()
      }
    })
    emitter.on("save-monitor-advanced-filters-modal", (event) => {
      if(this.AdvancedModalInstance != undefined && this.advancedModalQuestion != null){
        this.saveFilters(event)
        this.AdvancedModalInstance.destroy()
        this.AdvancedModalInstance = null
      }
    })

      /*this.AdvancedModalInstance.$on('lastStep', (e) => {
        this.advancedModalQuestion = {}
        this.AdvancedModalInstance.$el.remove()
        this.createSelectionModalInstance()
      });*/
  },
}
</script>
<style scoped lang="scss">
.transitionMedia-enter,
.transitionMedia-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  height: 0;
}

.transitionMedia-enter-active,
.transitionMedia-leave-active {
  transition: all 1s;
}
</style>
<style lang="scss">
.ChartMonitor--ConfigOpen {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  .ChartMonitor--Content{
    width: calc(100% - 343px);
  }
}

.ChartMonitor{
  width: 100%;
  display: flex;
  &:hover{
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
  .highcharts-loading{
    opacity: 1!important;
    left: 0!important;
    top: 0!important;
    width: 100%!important;
    height: 100%!important;
    color: #404040!important;
    font-size: 20px;
    font-family: 'Bariol';
  }
  .highcharts-loading-inner{
    top: 35%!important;
  }
  &--Media{
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    p{
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #96C800;
      cursor: pointer;
    }
  }
  &--MediaViewer{
    margin-bottom: 15px;
    .MediaItem{
      width: 100px;
      height: 100px;
      border-radius: 0;
      margin-bottom: 0;
    }
    .Icon{
      padding:0px;
    }
    .MediaItem__Overlay{
      border-radius:0;
    }
    #silentbox-overlay__container img{
      width: auto;
      height: auto;
    }
  }
  &--HeaderButtons{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 30px;
    padding-bottom:10px;
    padding-left: 15px;
    p{
      background: #F7F4F8;
      font-family: Bariol;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #404040;
      margin-right: 10px;
      padding: 5px 10px;
      cursor: pointer;
      &.selected{
        background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%) !important;
        color: white;
      }
    }
  }
  &--AddedFilters{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 30px;
    padding-bottom:10px;
    padding-left: 15px;
    h2{
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #939393;
      margin: 0 5px 5px 0;
    }
  }
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  &--Content{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 0 25px 0;
    background: white;
  }
  &--ConfigBorder{
    width: 297px;
    min-width: 297px;
    position:relative;
    overflow: hidden;

  }
  &--ConfigScroll{
    max-height: 100px;
    overflow-y:auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      width: 3px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }
  &--Config{
    background: #FFF5F5;
    width: 100%;
    height: 100%;
    padding: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .MonitorMessage{
      margin-bottom: 10px;
    }
    h1{
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #939393;
      margin:10px 0 10px 0;
      &:first-of-type{
        margin-top:0;
      }
    }
    input{
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      height: 36px;
      outline: none;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.01em;
      margin-bottom: 9px;
      &::placeholder{
        color: #BDBDBD;
      }
    }
    .commonButton{
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        color: white;
        padding: 10px 20px;
        border-radius: 2px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin: 10px 0;
        cursor: pointer;
        background: #96C800;
        box-shadow: 2px 2px 0px rgb(0 0 0 / 14%);
        position: relative;
        transition-duration: 0.4s;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: center;
        border: none;
        &:before {
          content:"";
          position: absolute;
          top: 0px;
          left: 0px;
          width: 0px;
          height: 0px;
          background: rgba(255, 255, 255, 0.2);
          transition: width 0.5s, height 0.5s;
        }
        &:hover:before {
          width: 100%;
          height: 100%;
        }
    }
    p{
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #96C800;
      margin: 0px;
      cursor: pointer;
      padding: 10px 0 10px 0;
    }
    textarea{
      resize: none;
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      outline: none;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.01em;
      margin-bottom: 9px;
      width: 100%;
      height: calc(100% - 9px);
      background-image: -webkit-linear-gradient(left, white 10px, transparent 10px), -webkit-linear-gradient(right, white 10px, transparent 10px), -webkit-linear-gradient(white 30px, #ccc 30px, #ccc 31px, white 31px);
      // background-image: -moz-linear-gradient(left, white 10px, transparent 10px), -moz-linear-gradient(right, white 10px, transparent 10px), -moz-linear-gradient(white 30px, #ccc 30px, #ccc 31px, white 31px);
      // background-image: -ms-linear-gradient(left, white 10px, transparent 10px), -ms-linear-gradient(right, white 10px, transparent 10px), -ms-linear-gradient(white 30px, #ccc 30px, #ccc 31px, white 31px);
      // background-image: -o-linear-gradient(left, white 10px, transparent 10px), -o-linear-gradient(right, white 10px, transparent 10px), -o-linear-gradient(white 30px, #ccc 30px, #ccc 31px, white 31px);
      // background-image: linear-gradient(left, white 10px, transparent 10px), linear-gradient(right, white 10px, transparent 10px), linear-gradient(white 30px, #ccc 30px, #ccc 31px, white 31px);
      background-size: 100% 100%, 100% 100%, 100% 31px;
      line-height: 31px;
      padding: 8px;
    }
  }
  &--Comments{
    position: absolute;
    right: 20px;
    top: -7px;
    cursor: pointer;
    div:first-of-type{
      position: relative;
      display: flex;
      div{
        position: absolute;
        right: calc(100% - 45px);
        top: -12px;
        border-radius: 8px;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        width: 282px;
        background: white;
        z-index: 1;
        padding: 12px 12px 8px 12px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.2s linear;
      }
      &:hover div{
        visibility: visible;
        opacity: 1;
      }
    }
    p{
      margin:0;
      font-size:12px;
      color: #939393;
      white-space: pre-wrap;
      margin-right: 5px;
    }
    span{
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      border-radius: 50%;
      padding: 6px;
      i{
        color:white;
        font-size: 21px;
      }
    }
  }
  &--Actions{
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 5px;
    border-left: 1px solid #EAEAEA;
    background: white;
    img{
      width: 100%!important;
      height: 100%!important;
      // filter: brightness(0%);
    }
    i{
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // color: black;
    }
    &--Box{
      width: 35px!important;
      height: 35px!important;
      cursor: pointer;
      display: flex;
      padding: 8px;
      align-items: center;
      justify-content: center;
      position: relative;
      &:nth-child(3){
        p{
          min-width: 112px;
        }
      }
      &:nth-child(6)::after{
        display:none;
      }
      &::after{
        content: '';
        position: absolute;
        left: 50%;
        width: 12px;
        height: 12px;
        top: -10px;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: white;
        border: 1px solid;
        border-color: transparent #FF1564 #FD7328 transparent;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.8s;
      }
      &:hover p, &:hover::after{
        visibility: visible;
        opacity: 1;
      }
      &--Tooltip{
        min-width: 106px;
        background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
        font-size: 13px;
        left: 50%;
        font-family: 'Open Sans', Helvetica, Arial, sans-serif;
        top: -10px;
        transform: translate(-50%, -100%);
        color: #444444;
        border-radius: 6px;
        z-index: 1;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.8s;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
          width: 100%;
          height: 100%;
          margin:1px;
          background: white;
          border-radius: 6px;
          padding: 6px 9px;
          text-align: center;
          font-family: 'Roboto Condensed';
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }
  &--Exporting{
    position:relative;
    div{
      position: absolute;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
      background: white;
      width: 100px!important;
      height: fit-content!important;
      bottom: -100px;
      z-index: 2;
      margin: 0;
      padding: 0;
      p{
        margin:0;
        cursor: pointer;
        padding: 0.8em 1em;
        color: rgb(51, 51, 51);
        font-size: 11px;
        width: 100%;
        &:hover{
          background: #F7F4F8;
        }
      }
    }
  }
  &--Title{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding:0 25px 0 25px;
    position: relative;
    h1{
      font-family: Lato;
      font-weight: bold;
      font-size: 20px!important;
      letter-spacing: 0.01em;
      color: #404040;
      margin: 0 0 0 20px!important;
      overflow-wrap:break-word;
    }
    img{
      height: 31px;
      width: 28px;
    }
  }
  &--Label{
    margin-left: 40px!important;
    height: 20px;
  }
  &--ChartTable{
    width: 100%;
    overflow-x:auto;
    overflow-y:auto;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }
  &--AnalysisContent{
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #f8f8f8;
    padding: 10px;
    border-radius: 6px;
    text-align: justify;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }
  &--AnalysisHeader{
    display: flex;
    position: relative;
    width: fit-content;
    border-radius: 7px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.1);
    padding: 3px 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    margin-bottom: 10px;
    cursor: pointer;
    &.selected{
      span{
        left: 10px;
      }
      button:first-of-type{
        color: white;
        background-clip: initial;
        background: transparent;
        -webkit-text-fill-color: white;
      }
      button:nth-of-type(2){
        background: linear-gradient(90deg,#ff1564,#fd7328);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    button{
      z-index: 1;
      background: linear-gradient(90deg,#ff1564,#fd7328);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size:12px;
      border: none;
      width: 50%;
      cursor: pointer;
      transition:0.5s;
      &:nth-of-type(2){
        color: white;
        background-clip: initial;
        background: transparent;
        -webkit-text-fill-color: white;
      }
    }
    span{
      position: absolute;
      width: calc(50% - 10px);
      height: calc(100% - 6px);
      left: 50%;
      background: #2c076e;
      border-radius: 7px;
      transition: 0.5s;
    }
  }
  &--ChartAnalysis{
    max-height: 450px!important;
  }
  &--ChartList{
    margin-left: 40px!important;
    height: 442px;
    &--Scroll{
      max-height: 247px;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar-track{
        background-color: #e6e6e6;
      }
      &::-webkit-scrollbar {
        background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
        width: 3px;
      }
      &::-webkit-scrollbar-thumb{
        border-radius: 20px;
        background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      }
    }
    &--Viewer{
      display: flex;
      flex-wrap: wrap;
    }
    p{
      margin: 0;
      margin-left: 8px;
    }
  }
  &--ChartGeographic{
    max-height: 600px!important;
  }
  &--Heatmap{
    // max-height: 600px;
    margin:0px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding:0 25px 0 25px;
    background: white;
  }
  &--HeatmapSelection{
    display: flex;
    p{
      background: #96C800;
      box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.14);
      border-radius: 5px;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      color: #FFFFFF;
      width: fit-content;
      padding: 5px 10px;
      margin-right: 10px;
      cursor: pointer;
      text-transform: uppercase;
      outline: none;
      border:none;
      position: relative;
      &.selected{
        border: 1px solid #96C800;
        background: white;
        color: #96C800;
      }
      &:before {
        content:"";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 0px;
        height: 0px;
        background: rgba(255, 255, 255, 0.2);
        transition: width 0.5s, height 0.5s;
      }
      &:hover:before {
        width: 100%;
        height: 100%;
      }
    }
  }
  &--Chart{
    width: calc(100% - 50px);
    margin: auto;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding:0 25px 0 25px;
    background: white;
    max-height: 350px;
    .textAnswerIndex{
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: 'Roboto Condensed';
      font-size: 18px;
      margin-right: 5px;
    }
  }
  &--SearchInput{
    position: relative;
    margin: 0 0 10px 0;
    input{
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      width: 100%;
      font-family: Roboto Condensed;
      font-weight: normal;
      font-size: 17px;
      letter-spacing: 0.01em;
      color: #969696;
      padding: 6px;
      outline: none;
      &::placeholder{
        opacity: 0.6;
      }
    }
    i{
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 18px;
      color:#969696;
    }
  }
  &--MultiRange{
    margin-top: 20px;
    width: 100%;
  }
  .pagination{
    margin: 15px 0 30px 0!important;
    a{
      outline: none;
      width: 50px;
    }
  }
  table{
    font-family: Lato;
    .copy-table{
      i{
        border: 1px solid #F7F4F8;
        padding: 5px;
        border-radius: 3px;
        background: #96C800;
        color: white;
        font-size:20px;
      }
      div{
        position: relative;
        &:hover:after{
          border: 1px solid #F7F4F8;
          color: black;
          padding: 3px 6px;
          font-size:13px;
          background: white;
          content: attr(data-title);
          position: absolute;
          font-family: Lato;
          width: 100px;
          top: 100%;
          left: calc(50% - 50px);
        }
      }
    }
    th,td{
      text-align: center;
      font-size: 16px;
      color: #404040;
      padding: 0 30px;
      height: 64px
    }
    th{
      background: #F7F4F8;
      border: 5px solid white;
    }
  }
}

@media (max-width: 768px) {
  .ChartMonitor{
    &--Chart{
      padding: 0 25px 0 10px;
    }
    &--Title{
      padding: 0 40px 0 25px;
    }
    &--Actions{
      z-index: 1;
      right: -5px;
      top: -19px;
      &--Box{
        margin:0 0 3px 0;
        width: 30px !important;
        height: 30px !important;
        padding: 6px;
        i{
          font-size: 16px;
        }
      }
    }
  }
}
</style>
