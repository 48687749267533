<template lang="html">
  <div class="CommentInput">
    <avatar class="CommentInput--UserAvatar" :src="user.avatar" :allowLabs="false"></avatar>
    <div class="CommentInput__Content">
      <div class="CommentInput__Header">
        <div class="CommentInput__DebateTopics" v-if="debateTopics && debateTopics.length > 0">
            <div class="CommentInput__DebateTopics--Selected" v-on:click="showDebateTopicSelector = !showDebateTopicSelector">
              <div v-if="selectedTopic == null">
                {{$t('activity_select_topic')}}
              </div>
              <div class="selected-topic" v-for="dt in debateTopics" v-show="selectedTopic == dt.topicId">
                {{dt.topicTagText}}
              </div>
              <i class="fas fa-sort-down"></i>
            </div>
            <div class="CommentInput__DebateTopics--List" v-if="showDebateTopicSelector">
                <div v-for="dt in debateTopics" :key="dt.topicId" v-on:click="selectDebateTopic(dt.topicId)" :style="{'background':dt.topicTagColor}">
                    {{dt.topicTagText}}
                </div>
            </div>
          </div>
          
      </div>
      <div class="CommentInput__Body">
        <textarea v-show="disableEntry" class="CommentInput__Field" :placeholder="$t('comment_placeholder_task')" disabled></textarea>
        <editor-content @input="handleInput" v-show="!disableEntry" :editor="editor" class="CommentInput__Field" :class="{'error':commentEmpty && editor.getText().length == 0}"/>
        <div v-if="commentEmpty && editor.getText().length == 0" class="CommentInput__Error">
          {{$t('error_comment_required_text')}}
        </div>
        <div v-if="topicNotSelected" class="CommentInput__Error">
          {{$t('error_comment_required_topic')}}
        </div>
      </div>
      <div class="CommentInput__ImageUpload" v-if="allowAttach" v-show="attachImagesOpened">
        <Dropzone
          ref="fileUploader"
          :uploadUrl="fileUploadPath"
          :uploadHeaders="fileUploadHeaders"
          :uploadText="$t('comment_upload_image')"
          acceptedFiles="image/jpg,image/jpeg,image/png"
          v-on:file-uploaded="handleAddImage"
          v-on:file-added="isAddingFile"
          v-on:file-removed="handleRemoveImage">
        </Dropzone>
      </div>
      <div class="CommentInput__VideoUpload" v-if="allowAttach" v-show="attachVideoOpened">
        <Dropzone
          ref="videoUploader"
          :uploadUrl="videoUploadPath"
          :uploadHeaders="fileUploadHeaders"
          :uploadText="$t('comment_upload_video')"
          :maxFiles="1"
          acceptedFiles="video/*"
          v-on:file-uploaded="handleAddVideo"
          v-on:file-added="isAddingFile"
          v-on:file-removed="handleRemoveVideo">
        </Dropzone>
      </div>
      <div class="CommentInput__FilesUpload" v-if="allowAttach" v-show="attachFilesOpened">
        <Dropzone
          ref="filesUploader"
          :uploadUrl="fileUploadPath"
          :uploadHeaders="fileUploadHeaders"
          :uploadText="$t('comment_upload_file')"
          v-on:file-uploaded="handleAddFiles"
          v-on:file-added="isAddingFile"
          v-on:file-removed="handleRemoveFiles">
        </Dropzone>
      </div>
      <div class="CommentInput__Footer">
        <div class="CommentInput__Actions" v-if="allowAttach">
          <icon-button
            class="CommentInput__AttachMediaButton"
            icon="image"
            v-on:click="toggleAttachImages">
          </icon-button>
          <icon-button
            class="CommentInput__AttachMediaButton CommentInput__AttachMediaButton--Video"
            icon="film"
            v-on:click="toggleAttachVideo">
          </icon-button>
          <icon-button
            class="CommentInput__AttachMediaButton CommentInput__AttachMediaButton--Files"
            icon="file"
            v-on:click="toggleAttachFiles">
          </icon-button>
        </div>
        <div class="CommentInput__SubmitContainer">
          <CommunityButton
            class="CommentInput--SubmitButton"
            :disabled="isUploading || disableSubmit || disableEntry || isLoading"
            v-on:click="handleSubmitComment">
            <Spinner v-if="isLoading"></Spinner>
            {{ $t(isLoading ? '' : 'comment_action_publicate')}}
          </CommunityButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'
import Avatar from '../Avatar'
import Dropzone from '../Dropzone'
import IconButton from '../IconButton'
import { merge, filter } from 'lodash'
import CommunityButton from '@/components/CommunityButton.vue'
import Swal from 'sweetalert2'

import Mention from '@tiptap/extension-mention'
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import suggestion from '../../utils/suggestion.js'

import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Placeholder from '@tiptap/extension-placeholder'
import Spinner from '../Spinner.vue'

export default {
  name: 'CommentInput',

  props: {
    user: {
      type: Object,
      default: null
    },
      isLoading: {
          type: Boolean,
          default: false,
      },
    allowAttach: {
      type: Boolean,
      default: false,
    },

    disableSubmit: {
      type: Boolean,
      default: false,
    },

    disableEntry: {
      type: Boolean,
      default: false,
    },

    fileUploadPath: {
      type: String,
      default: null,
    },

    videoUploadPath: {
      type: String,
      default: null,
    },

    fileUploadHeaders: {
      type: Object,
      default: null,
    },
    debateTopics: {
      type: Array,
      default: null,
    },
    fetchRecomendationUsers: {
      type: Function,
      default: null
    },
    onlineUsers: {
      type: Object,
      default: null
    },
    commentId: { 
      type: [Number, String],
      default: null,
    },
    nickname: {  
      type: String,
      default: ''
    }
  },
  components: {
    Avatar,
    Dropzone,
    IconButton,
    CommunityButton,
    EditorContent,
      Spinner
  },

  data() {
    return {
      comment: '',
      images: [],
      video: '',
      files: [],
      commentEmpty: false,
      selectedTopic: null,
      topicNotSelected: false,
      attachImagesOpened: false,
      attachVideoOpened: false,
      attachFilesOpened: false,
      isUploading: false,
      showDebateTopicSelector: false,
      _uid: getCurrentInstance().uid,
      suggestionUsers: [],
      editor: null,
      editorContent: '',
      activityId: this.$route.params.id
    }
  },
  mounted() {
    this.initEditor()
    this.loadDraft()
  },
  watch: {
    '$route.params.id': function(newId) {
      this.activityId = newId
      this.loadDraft()
    },
    'commentId': function(newCommentId) {
      this.loadDraft()
    },
  },
  beforeUnmount() {
    this.editor.destroy()
  },
  methods: {
    initEditor() {
      suggestion.items = async ({ query }) => {
        let vm = this;
        let users = await this.fetchRecomendationUsers(query);
        users = users.map(function(user) {
          if (vm.onlineUsers != null) user.online = vm.onlineUsers.testersOnlineCondensed.includes(user.identifier);
          user.type = 'user';
          return user;
        });
        users.unshift({nickname: 'Moderador', type: 'moderator', identifier: -1});
        _.merge(this.suggestionUsers, users);
        return users;
      };

      const savedContent = this.getWithExpiry(this.getDraftKey()) || '';

      this.editor = new Editor({
        extensions: [
          Document,
          Paragraph,
          Text,
          Mention.configure({
            HTMLAttributes: {
              class: 'mention',
            },
            suggestion,
          }),
          Placeholder.configure({
            placeholder: this.$t('comment_placeholder'),
          }),
        ],
        content: savedContent,
        onUpdate: ({ editor }) => {
          this.isEmpty = editor.getText().trim().length === 0;
          this.updateEditorContent();
        },
      });

      this.insertMentionIfNeeded();
    },

    insertMentionIfNeeded() {
      const draftContent = this.getWithExpiry(this.getDraftKey()) || '';
      if (this.nickname && !draftContent.includes(`data-id="${this.nickname}"`)) {
        const mentionNode = this.editor.schema.nodes.mention.create({
          id: this.nickname,
          label: `${this.nickname}`
        });
        const transaction = this.editor.state.tr.replaceSelectionWith(mentionNode);
        this.editor.view.dispatch(transaction);
        this.nickname = ''; 
      }
    },

    getDraftKey() {
      return `editorContent-${this.activityId}-${this.commentId}`
    },

    selectDebateTopic(id) {
      this.selectedTopic = id
      this.showDebateTopicSelector = false
    },

    handleSubmitComment(e) {
      this.comment = this.editor.getHTML()
      e.preventDefault()
      this.commentEmpty = false
      this.topicNotSelected = false
      if (this.editor.getText().length == 0) {
        this.commentEmpty = true
        return
      }
      if (this.debateTopics && this.debateTopics.length > 0 && this.selectedTopic === null) {
        this.topicNotSelected = true
        return
      }

      this.setMentionUserJson()
      let payload = {
        comment: this.comment,
        images: this.images.length == 0 ? null : this.images,
        video: this.video.length == 0 ? null : this.video,
        files: this.files.length == 0 ? null : this.files,
      }

      if (this.selectedTopic !== null && this.selectedTopic !== undefined) {
            payload['topicId'] = this.selectedTopic;
        }
      if (payload.comment.includes('<script') || payload.comment.includes('<style')) {
        Swal.fire({
          text: this.$t('msg_invalid_comment_data'),
          icon: 'error',
          confirmButtonText: this.$t('msg_agree'),
          customClass: {
            popup: 'swal-customError'
          }
        })
        return
      }
      this.$emit('submit-comment', payload)
      this.resetComment()
    },

    setMentionUserJson() {
      for (let user of this.suggestionUsers) {
        const mentionSpan = document.querySelector('[data-id="' + user.nickname + '"]');
        if (mentionSpan != undefined) {
          const mentionJson = {"type": user.type, "nick": user.nickname};
          this.comment = this.comment.replace('@' + user.nickname, JSON.stringify(mentionJson));
        }
      }
    },

    resetComment() {
      this.comment = ''
      this.editor.commands.clearContent()
      this.images = []
      this.files = []
      if (this.$refs.fileUploader) {
        this.$refs.fileUploader.reset()
      }
      if (this.$refs.videoUploader) {
        this.$refs.videoUploader.reset()
      }
      if (this.$refs.filesUploader) {
        this.$refs.filesUploader.reset()
      }
      this.video = ''
      this.attachImagesOpened = false
      this.attachVideoOpened = false
      this.attachFilesOpened = false
      localStorage.removeItem(this.getDraftKey())

      if (this.nickname) {
        this.editor.commands.setContent(`@${this.nickname} `);  
      }
    },

    handleAddImage(file, response) {
      this.images.push({
        fileId: response.id,
        filePath: response.path,
        uuid: file.uuid,
      })
      this.isUploading = false
    },

    handleRemoveImage(file) {
      this.images = _.filter(this.images, function (o) { return o.uuid != file.uuid })
    },

    handleAddVideo(file, response) {
      this.video = response.path
      this.isUploading = false
    },

    handleRemoveVideo(file) {
      this.video = ""
    },

    handleAddFiles(file, response) {
      this.files.push({
        fileId: response.id,
        filePath: response.path,
        uuid: file.uuid,
      })
      this.isUploading = false
    },

    isAddingFile(file) {
      this.isUploading = true
    },

    handleRemoveFiles(file) {
      this.files = _.filter(this.files, function (o) { return o.uuid != file.uuid })
    },

    toggleAttachImages() {
      this.attachImagesOpened = !this.attachImagesOpened
    },

    toggleAttachVideo() {
      this.attachVideoOpened = !this.attachVideoOpened
    },

    toggleAttachFiles() {
      this.attachFilesOpened = !this.attachFilesOpened
    },
    
    getEditorContent() {
      if (this.editor) {
        this.editorContent = this.editor.getHTML()
      }
    },

    updateEditorContent() {
      this.editorContent = this.editor.getHTML()
      this.setWithExpiry(this.getDraftKey(), this.editorContent, 172800000); // 2 dias en milisegundos
    },

    handleInput() {
      this.editorContent = this.editor.getHTML()
      console.log('Editor Content:', this.editorContent)
      this.setWithExpiry(this.getDraftKey(), this.editorContent, 172800000); // 2 dias en milisegundos
    },

    setWithExpiry(key, value, ttl) {
      const now = new Date()
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item))
    },

    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key)
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
      }
      return item.value
    },

    loadDraft() {
      const draftContent = this.getWithExpiry(this.getDraftKey()) || '';
      if (draftContent) {
        this.editor.commands.setContent(draftContent);
      } else {
        this.editor.commands.clearContent();
      }
      this.insertMentionIfNeeded();
    },
  }
}
</script>


<style lang="scss">
.CommentInput{
  .ProseMirror{
    outline: none;
    .mention{
      color: #00acee;
    }
    > * + * {
      margin-top: 0.75em;
    }
    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: #adb5bd;
      pointer-events: none;
      height: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.CommentInput {
  display: flex;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
  padding: 25px 50px;
  &--UserAvatar {
    width: 52px;
    height: 52px;
    position: absolute;
    border:1px solid white;
    box-shadow: -5px 6px 27.84px 1.16px rgba(0, 0, 0, 0.17);
    top: 25px;
    left: -13px;

    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }

  &__Content {
    background-color: #fff;
    padding: 1em;
    width: 100%;
    box-shadow: 0 0 8px rgba(0,0,0,0.1);
    border: 1px solid rgba(0, 0, 0, 0.08);
    @media (max-width: 768px) {
      font-size: 0.7em;
    }
  }

  &__Header {
    display: flex;
    align-items: baseline;
    select{
      border:0;
      outline: none;
      option{
        color:white;
      }
    }
  }
  &__Body {
    margin-bottom: 1em;
  }

  &__ImageUpload {
    margin-bottom: 1em;
  }

  &__VideoUpload {
    flex-grow: 1;
    margin-bottom: 1em;
    .VideoUploadInput {
      border-radius: 32px;
      border: none;
      height: 32px;
      line-height: 32px;
      background-color: #F2F2F2;
      outline: none;
      padding: 0 16px;
      width: 100%;

      &:hover {
        background-color: #DFDFDF;
      }

      &.error {
        border: 1px solid red;
      }
    }
  }

  &__Field {
    width: 100%;
    border: none;
    resize: none;
    outline: none;
    padding: 0;
    font-size: 1em;
    font-family: Roboto condensed;
    &.error{
      border: 1px solid #d9534f;
    }
    &:disabled{
      min-height: 75px;
    }
    &:empty:before {
        content: attr(data-placeholder);
        color: #555;
    }
  }

  &__Error{
    color: #d9534f;
    font-size: 10px
  }

  &__Footer {
    display: flex;
    flex-direction: row;
  }

  &__SubmitContainer{
    position:relative;
  }

  &--SubmitButton {
    margin-left: auto;
  }

  &__AttachMediaButton {
    background-color: #F2F2F2;
    margin-right: .5em;
    cursor: pointer;

    &--Video :deep(i) {
      font-size: 1.1em;
    }

    &:hover {
      background-color: #DFDFDF;
    }
  }

  &__Actions {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
  }

  .CommentInput__DebateTopics {
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    position: relative;
    z-index: 1;
    margin-bottom: 5px;
  
    &--Selected {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      cursor: pointer;
      background: #f8f8f8; 
      border-radius: 5px 5px 0 0; 
      border: 1px solid #ddd; 
      width: 100%; 
      
    }
  
    &--List {
      color: white;
      border-top: 1px solid #f1f1f1;
      background: var(--primary-color);
      width: 100%; 
      max-height: 200px;
      overflow-y: auto;
      border-radius: 0 0 4px 4px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
      div {
        display: block; 
        padding: 10px;
        border-bottom: 1px solid #f1f1f1;
        width: 100%; 
        cursor: pointer;
        transition: background 0.3s;
        box-sizing: border-box; 
        &:hover {
          background: lighten(rgb(164, 0, 252), 10%)!important;
        }
  
        &:last-child {
          border-bottom: none;
        }
      }
    }
    }
}
  
.selected-topic {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
@keyframes shake {
  0% {
    transform: rotate(2deg);
  }
  50% {
   transform: rotate(-3deg);
  }
  70% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
</style>
