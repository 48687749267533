<template>
    <div class="VideoAnalysis">
        <div class="VideoAnalysis--Content">
            <p>{{ analysis }}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "VideoAnalysis",
    props: {
        analysis: String,
    },
    methods: {
        itemSelected(event) {
            const selectedItem = this.comments.find(x => x.comment === event.text);
            if (selectedItem != undefined || selectedItem != null) {
                this.$emit('click-frame', selectedItem.secondsFromStart)
            }
        }
    }
};
</script>
<style scoped lang="scss">
.VideoAnalysis {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &--Content {
        height: 100%;
        font-family: 'Lato';
        font-weight: 700;
        display: flex;
        flex-direction: column;
        gap: 17px;
        padding-left: 3px;
        background-color: white;
        overflow-y: auto;

        p {
            padding: 10px;
            text-align: left;
        }
    }
}
</style>
