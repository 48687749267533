<template>
  <div id="uxVideoControls">
    <IconPlayButton v-show="!isPlaying" class="playButton" @click="handlePlay" />
    <IconPauseButton v-show="isPlaying" class="stopButton" @click="handleStop" />
    <div class="progressBar" ref="progressBar" @click="handleProgressChange">
      <div class="inner" :style="{ width: progressPercent + '%' }"></div>
      <!-- <template v-for="note in notes">
        <div
          class="note"
          :style="{ left: getNotePercentPosition(note.second) + '%' }"
        ></div>
      </template> -->
    </div>
    <div class="volume">
      <IconVolumeOn v-show="!isMuted" @click="toggleMute" />
      <IconVolumeOff v-show="isMuted" @click="toggleMute" />
    </div>
    <div class="speedControls">
      <i class="fas fa-cog" @click="toogleSpeedControls()"></i>
      <div class="speed-options" :class="{ 'show': showSpeedControl }">
        <button v-on:click="changeSpeed(1)">1x</button>
        <button v-on:click="changeSpeed(1.5)">1.5x</button>
        <button v-on:click="changeSpeed(2)">2x</button>
        <button v-on:click="changeSpeed(2.5)">2.5x</button>
      </div>
    </div>
    <div class="timeInfo">
      <p>{{ currentTime }}</p>
      <p>|</p>
      <p>{{ videoDuration }}</p>
    </div>
  </div>
</template>
<script setup>
import { onBeforeMount, ref } from "vue";
import IconPlayButton from "../icons/IconPlayButton.vue";
import IconPauseButton from "../icons/IconPauseButton.vue";
import IconVolumeOn from "../icons/IconVolumeOn.vue";
import IconVolumeOff from "../icons/IconVolumeOff.vue";
const emit = defineEmits(["progressChanged", "play", "stop", "toggleMute", "changeSpeed"]);
const props = defineProps([
  "currentTime",
  "videoDuration",
  "progressPercent",
  "notes",
  "videoTotalSeconds",
]);
const progressBar = ref(null);
const isPlaying = ref(false);
const isMuted = ref(false);
const showSpeedControl = ref(false);

onBeforeMount(() => {
  window.addEventListener("keyup", function (e) {
    if (e.key == " " || e.code == "Space") {
      if (isPlaying.value) {
        handleStop();
      } else {
        handlePlay();
      }
    }
  });
});
const handleProgressChange = (e) => {
  const rect = e.target.getBoundingClientRect();
  const clickPosition = e.clientX - rect.left;
  let newProgressPercent =
    (clickPosition * 100) / progressBar.value.getBoundingClientRect().width;
  emit("progressChanged", newProgressPercent);
};
const getNotePercentPosition = (second) => {
  return (second / props.videoTotalSeconds) * 100;
};
const handlePlay = () => {
  isPlaying.value = true;
  emit("play");
};
const handleStop = () => {
  isPlaying.value = false;
  emit("stop");
};
const toggleMute = () => {
  isMuted.value = !isMuted.value;
  emit("toggleMute", isMuted.value);
};

const changeSpeed = (speed) => {
  emit("changeSpeed", speed);
  toogleSpeedControls();
}

const toogleSpeedControls = () => {
  showSpeedControl.value = !showSpeedControl.value
}
</script>
<style lang="scss" scoped>
#uxVideoControls {
  background-color: #8134ff;
  padding: 8px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border-radius: 30px;
  height: 100%;
  width: 70%;
  .playButton,
  .stopButton {
    cursor: pointer;
  }
  .progressBar {
    width: 70%;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    .inner {
      height: 100%;
      background-color: white;
      border-radius: 20px;
    }
    .note {
      position: absolute;
      top: 0;
      height: 100%;
      width: 1.5px;
      background-color: red;
      pointer-events: none;
    }
  }
  .volume {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .speedControls {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 20px;
      color: white;
    }

    /* Ocultar los botones de velocidad por defecto */
    .speed-options {
      position: absolute;
      display: none;
      /* Oculto por defecto */
      flex-direction: column;
      gap: 10px;
      background: rgba(0, 0, 0, 0.8);
      padding: 10px;
      border-radius: 10px;
      bottom: calc(100% + 5px);
      /* Ajusta esto según la altura de tus botones */
      left: 50%;
      /* Alinea el centro del contenedor respecto a la tuerca */
      transform: translateX(-50%);
      /* Centra horizontalmente */
      z-index: 1;
      /* Para asegurarse de que aparezca encima */

      button {
        background-color: rgba(255, 255, 255, 0.2);
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }

      button:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    /* Mostrar los botones al hacer hover sobre la tuerca */
    .speed-options.show {
      display: flex;
      flex-direction: column-reverse;
      /* Mostrar en hover */
    }
  }
  .timeInfo {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    color: white;
  }
}
</style>
